<template>
  <div class="footer">
    <div class="footer-bj" :style="'height:' + clientHeight + 'px'">
      <div class="conter top">
        <div class="title">
          <h1>联系我们</h1>
          <p>请写下联系方式，以便我们能及时联系你</p>
        </div>
        <div class="input">
          <div>
            <el-input
              v-model="companyName"
              @blur="companyNameRules()"
              placeholder="企业名/姓名"
            ></el-input>
            <p class="tips">{{ tipscompanyName }}</p>
          </div>
          <div>
            <el-input
              v-model="phoneNumber"
              maxlength="11"
              oninput="value=value.replace(/[^\d]/g,'')"
              @blur="adminPhoneRules()"
              placeholder="手机号"
            ></el-input>
            <p class="tips">{{ tipsMsg }}</p>
          </div>
        </div>
        <div class="btn" @click="submit">提交</div>
      </div>
      <div class="bottom">
        <div class="conter">
          <div>
            <p>电话：028-85326217</p>
            <p style="margin-right: 53px">邮箱：Email:knot@scknot.com</p>
            <p>地址：成都市高新区天府三街吉泰路666号福年广场T2-1205</p>
          </div>
          <div>
            <p>© 2011-2021 成都卡恩特医疗科技有限公司 蜀ICP备11016462号-1</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createMessage } from '@/api/journalism'
export default {
  data() {
    return {
      clientHeight: 0,
      phoneNumber: '',
      companyName: '',
      tipsMsg: '',
      tipscompanyName: ''
    }
  },
  methods: {
    // 获取当前高度
    getHeight() {
      // 页面高度
      this.clientHeight = document.body.clientHeight - 67
    },
    async submit() {
      if (!this.companyName) {
        this.tipscompanyName = '请输入企业/姓名'
      }
      const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!this.phoneNumber) {
        this.tipsMsg = '请输入电话号码'
        return
      } else if (this.phoneNumber && !reg.test(this.phoneNumber)) {
        this.tipsMsg = '请输入正确的电话号码'
        return
      } else {
        this.tipsMsg = ''
      }
      const { code, msg } = await createMessage({
        companyName: this.companyName,
        phoneNumber: this.phoneNumber
      })
      if (code === '0') {
        this.$message.success(msg)
        this.companyName = ''
        this.phoneNumber = ''
      } else {
        this.$message.error(msg)
      }
    },
    adminPhoneRules(v) {
      const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!this.phoneNumber) {
        this.tipsMsg = '请输入电话号码'
      } else if (this.phoneNumber && !reg.test(this.phoneNumber)) {
        this.tipsMsg = '请输入正确的电话号码'
      } else {
        this.tipsMsg = ''
      }
    },
    companyNameRules(v) {
      if (!this.companyName) {
        this.tipscompanyName = '请输入企业/姓名'
      } else {
        this.tipscompanyName = ''
      }
    }
  },
  created() {
    this.getHeight()
  }
}
</script>

<style lang="less" scoped>
.footer {
  // width: 100%;
  // height: 100%;
  .footer-bj {
    background: url('../../assets/images/webhome/beijingwu.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // position: relative;
    .top {
      padding-top: 6%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .title {
        text-align: center;
        font-size: 54px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #20adff;
        margin: 0 !important;
        h1 {
          letter-spacing: 10px;
        }
        p {
          font-size: 27px;
          font-family: Adobe Heiti Std;
          // padding-top: 6%;
        }
      }
      .input {
        display: flex;
        // margin-top: 6%;
        margin-left: 40px;
        .el-input {
          width: 444px;
          height: 48px;
          border-radius: 8px;
          /deep/.el-input__inner {
            border: 1px solid #20adff;
            background-color: transparent;
            color: #fff;
          }
          /deep/.el-input__inner:hover {
            border: 2px solid #20adff;
          }
        }
        .el-input:nth-child(1) {
          margin-right: 68px;
        }
      }
      .btn {
        text-align: center;
        background: url('../../assets/images/webhome/anniu.png');
        background-size: 100%;
        width: 337px;
        height: 50px;
        line-height: 50px;
        cursor: pointer;
        margin-right: 30px;
        // margin-top: 1.5%;
        border-radius: 8px;
        color: #20adff;
      }
      .tips {
        height: 20px;
        color: #ccc;
        font-size: 12px;
      }
    }
    .bottom {
      -moz-user-select: none; /* Firefox私有属性 */
      -webkit-user-select: none; /* WebKit内核私有属性 */
      -ms-user-select: none; /* IE私有属性(IE10及以后) */
      -khtml-user-select: none; /* KHTML内核私有属性 */
      -o-user-select: none; /* Opera私有属性 */
      user-select: none; /* CSS3属性 */
      height: 113px;
      background-color: #040c1b;
      box-sizing: border-box;
      width: 100%;
      color: #fff;
      // position: absolute;
      // bottom: 0;
      .conter {
        div:nth-child(1) {
          display: flex;
          font-size: 17px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          justify-content: center;
          margin-top: 10px;
          p {
            margin-right: 20px;
          }
          p :nth-child(2) {
            color: red;
          }
        }
        div:nth-child(2) {
          // display: flex;
          // justify-content: center;
          font-size: 13px;
          // text-align: center;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 13px;
          opacity: 0.28;
          p {
            // text-align: center;
            margin-left: 350px;
            span {
              display: inline-block;
              // padding-left: 20px;
              // margin-left: 10px;
            }
            span:first-child {
              padding-right: 30px;
            }
          }
        }
      }
    }
  }
  h1 {
    font-size: 54px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #20adff;
    margin: 0 !important;
  }
  /deep/ input::-webkit-input-placeholder {
    color: #20adff !important;
  }
  /deep/ input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #20adff;
  }
  /deep/ input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #20adff;
  }
  /deep/ input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #20adff;
  }
}
</style>
